.container {
    max-width: 1100px;
    margin: auto;
    padding: 0 2rem;
}

@media screen and (max-width: 500px) {
    .container {
        padding: 0 1rem;
    }
}

@media screen and (max-width: 400px) {
    .container {
        padding: 0 0.5rem;
    }
}