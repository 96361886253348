html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --gray: #dbdbdb;
  --gray-dark: #979797;
  --black-light: #1c1c1c;
  --menu-black: #32373C;
  --white: #eaeaea;
  --light-bg: linear-gradient(240deg, #FFFFFF 0%, var(--gray) 100.28%);
}

a {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

h1, h2, h3, h4 {
  line-height: 1.3;
}

*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}