.page {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    width: 50%;
    max-width: 30rem;
    min-height: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.code {
    font-size: 4rem;
    color: var(--menu-black);
    text-align: center;
    margin: 1rem;
}

.line {
    font-size: 1.1rem;
    text-align: center;
    color: #5c2a9d;
    margin: 1rem;
}

.btn {
    display: block;
    margin: 1rem auto 0;
    font-size: 1.1rem;
    background-color: #5c2a9d;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 0.3rem;
    border: none;
    outline: none;
    cursor: pointer;
    border: 1px solid #5c2a9d;
    transition: all 200ms ease-in;
}

.btn:hover {
    background-color: white;
    color: #5c2a9d;
}